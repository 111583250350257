






























































import useJwt from '@/auth/jwt/useJwt'
import { defineComponent, onMounted, ref } from '@vue/composition-api'
import { useFotoStore } from './conta-foto.store'
import Vue from 'vue'
import ToastificationContent from '@/@core/components/toastification/ToastificationContent.vue'
import { POSITION } from 'vue-toastification'
import { jwtDecode } from 'jwt-decode'

export default defineComponent({
  setup(props, { emit }) {
    // Input
    const upload = ref<HTMLInputElement | null>(null)
    const imagePreview = ref<HTMLDivElement | null>(null)
    const filenameLabel = ref<HTMLSpanElement | null>(null)
    const isEventListenerAdded = ref(false)

    // Foto
    const store = useFotoStore()

    // Usuario
    const user = jwtDecode(JSON.parse(localStorage.getItem('userData')))['userData']

    // Clicar no input
    const abrirSelecaoDeImagem = () => {
      upload.value?.click()
    }

    // Trocar a foto
    const mudarPreview = (src: string) => {
      store.foto = src
    }

    // Trocar a foto
    const uploadFoto = async (file: string | Blob) => {
      try {
        const formData = new FormData()
        formData.append('file', file)

        await useJwt.postDocumento(`cadastro/user/${user.id}/foto-perfil`, () => {}, formData)

        
        Vue.$toast(
          {
            component: ToastificationContent,
            props: {
              title: 'Successo',
              text: 'Foto alterada com sucesso',
              icon: 'BellIcon',
              variant: 'success',
              className: 'toast-text',
            },
            options: {
              dangerouslyUseHTMLString: true
          },
          },
          {
            position: POSITION.TOP_RIGHT,
            timeout: 8000,
          },
        )
      } catch (error) {
        console.error(error)
      }
    }

    const trocarFoto = (evento: Event) => {
    const file = (evento.target as HTMLInputElement).files?.[0]
    if (file) {
      store.$state.arquivo = file
      emit('foto', file)
      
      const reader = new FileReader()
      reader.onload = (event) => {
        mudarPreview(event.target.result as any)
        uploadFoto(file) 
      }
      reader.readAsDataURL(file)
    } else {
      emit('foto', null)
      filenameLabel.value.textContent = ''
      imagePreview.value.innerHTML = `<div class="bg-gray-200 h-48 rounded-lg flex items-center justify-center text-gray-500">No image preview</div>`
      imagePreview.value.classList.add('border-dashed', 'border-2', 'border-gray-400')
    }
  }

  onMounted(async () => {
    try {
      const response = await useJwt.get(`cadastro/user/${user.id}/foto-perfil`)
      if (response.data && response.data.foto) {
        mudarPreview(response.data.foto)
      }
    } catch (error) {
      console.error(error)
    }
  })

    return {
      abrirSelecaoDeImagem,
      upload,
      imagePreview,
      filenameLabel,
      store,
      isEventListenerAdded,
      trocarFoto,
    }
  },
})
