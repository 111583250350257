<template>
  <b-card>
    <!-- media  "optionsLocal.avatar" :src="require('@/assets/images/avatars/13-small.png')" -->

    <!-- media  "dados.avatar" :src="require('@/assets/images/avatars/13-small.png')" -->
    <!--/ media -->
    <b-card-text style="display: block; text-align: center"> </b-card-text>
    <!--/ media -->

    <validation-observer ref="formConta">
      <!-- form -->
      <b-form class="mt-2">
        <b-row>
          <b-col sm="3">
            <ConfiguracaoContaGeralFoto @foto="mudarFoto" />
          </b-col>
          <b-col sm="9">
            <b-row>
              <!-- NOME -->
              <b-col sm="4">
                <b-form-group label="Nome" label-for="account-name">
                  <b-form-input v-model="optionsLocal.name" name="name" placeholder="Nome" rules="required" :disabled=true />
                </b-form-group>
              </b-col>

              <!-- EMAIL -->
              <b-col sm="4">
                <b-form-group label="E-mail" label-for="email">
                  <b-form-input
                    v-model="optionsLocal.email"
                    name="email"
                    placeholder="Email"
                    rules="required"
                    :disabled=true
                  />
                </b-form-group>
              </b-col>

              <!-- CARGO   -->
              <b-col sm="4">
                <b-form-group label="Cargo" label-for="cargo">
                  <b-form-input v-model="optionsLocal.cargo" name="cargo" placeholder="Cargo" rules="required" />
                </b-form-group>
              </b-col>

              <!-- GENERO -->
              <b-col sm="4">
                <b-form-group label="Gênero" label-for="genero">
                  <b-form-select
                    name="genero"
                    v-model="optionsLocal.genero"
                    :options="generoOpcoes"
                    rules="required"
                    :disabled=true
                  >
                  </b-form-select>
                </b-form-group>
              </b-col>

              <!-- aniversario -->
              <b-col md="4">
                <b-form-group label-for="dataNascimento" label="Data de Nascimento">
                  <flat-pickr
                    v-model="optionsLocal.dataNascimento"
                    class="form-control"
                    name="dataNascimento"
                    :config="pickrAniversario"
                    placeholder="Data de Nascimento"
                    :disabled=true
                  />
                </b-form-group>
              </b-col>

              <!-- telefone -->
              <b-col md="4">
                <b-form-group label-for="telefone" label="Telefone">
                  <cleave
                    id="telefone"
                    v-model="optionsLocal.telefone"
                    class="form-control"
                    :raw="false"
                    :options="clevePhone"
                    placeholder="Telefone"
                    :disabled=true
                  />
                </b-form-group>
              </b-col>

              <!-- bio -->
              <b-col cols="12">
                <b-form-group label="Biografia" label-for="bio-area">
                  <b-form-textarea
                    id="bio-area"
                    v-model="optionsLocal.bio"
                    rows="4"
                    placeholder="Descreva aqui um pouco de você..."
                  />
                </b-form-group>
              </b-col>

              <b-col cols="12">
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  class="mt-2 mr-1"
                  @click.prevent="salvar"
                >
                  Salvar
                </b-button>
                <b-button
                  v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                  variant="outline-secondary"
                  type="reset"
                  class="mt-2"
                  @click.prevent="limpar"
                >
                  Limpar
                </b-button>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
  //validacao
  import { ValidationProvider, ValidationObserver } from 'vee-validate'
  import { required } from '@validations'
  import Ripple from 'vue-ripple-directive'
  import useJwt from '@/auth/jwt/useJwt'

  //imagem
  import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
  import { ref } from '@vue/composition-api'
  import vSelect from 'vue-select'

  // Para datas
  import flatPickr from 'vue-flatpickr-component'
  import 'flatpickr/dist/flatpickr.css'
  import { Portuguese } from 'flatpickr/dist/l10n/pt.js'

  // Para formatar o numero
  import Cleave from 'vue-cleave-component'
  import 'cleave.js/dist/addons/cleave-phone.br'

  import VueRouter from 'vue-router'

  // Foto
  import ConfiguracaoContaGeralFoto from './geral/ConfiguracaoContaGeralFoto.vue'
  import { useFotoStore } from './geral/conta-foto.store'

  import Swal from 'sweetalert2/dist/sweetalert2.js'
  import 'sweetalert2/src/sweetalert2.scss'
  import { jwtDecode } from 'jwt-decode'
  import cryptUserData from '@/@core/auth/utils/Crypt-User-Data'
  import updateUserData from '@/@core/auth/utils/Update-User-Data'

  export default {
    components: {
      ConfiguracaoContaGeralFoto,
      Cleave,
      flatPickr,
      ValidationProvider,
      ValidationObserver,
      vSelect,
      VueRouter,
    },
    directives: {
      Ripple,
    },
    props: {
      generalData: {
        type: Object,
        default: () => {},
      },
    },
    data() {
      return {
        cryptUserData,
        foto: null,
        optionsLocal: {},
        blogFile: null,
        required,
        store: useFotoStore(),
        generoOpcoes: [
          { value: '', text: 'Selecione' },
          { value: 'M', text: 'Masculino' },
          { value: 'F', text: 'Feminino' },
        ],
        clevePhone: {
          phone: true,
          phoneRegionCode: 'BR',
          delimiter: ' ',
        },
        pickrAniversario: {
          altFormat: 'd/m/Y',
          altInput: true,
          dateFormat: 'Y-M-d',
          locale: Portuguese,
        },
      }
    },
    mounted() {
      this.fetchUserData()
    },
    methods: {
      async fetchUserData() {
        try {
          const userResponse = await useJwt.get(`cadastro/user/${this.generalData.id}`)
          this.optionsLocal = { ...this.optionsLocal, ...userResponse.data } // Atualiza optionsLocal com os dados
        } catch (error) {
          console.error('Erro ao buscar dados do usuário:', error)
        }
      },
      mudarFoto(foto) {
        this.foto = foto
      },
      async salvarFoto(foto) {
        const formData = new FormData()
        formData.append('file', foto)

        await useJwt.post(`cadastro/user/${this.generalData.id}/foto-perfil`, formData)
      },
      limpar() {
        this.store.$state.foto = null
        this.store.$state.arquivo = null
        this.optionsLocal = {}
        this.optionsLocal.id = JSON.parse(JSON.stringify(this.generalData.id))
      },
      onRemoved() {
        this.optionsLocal = { ...this.optionsLocal, foto: null }
      },
      salvar(bvModalEvt) {
        const store = this.store
        bvModalEvt.preventDefault()

        this.$refs.formConta.validate().then(async (success) => {
          if (success) {
            if (this.optionsLocal.id === undefined) {
              this.$swal({
                title: 'Atenção!',
                text: 'É proibido o cadastro de novos usuários nessa página',
                icon: 'error',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              })
              return // Adicione um return aqui para sair da função
            }

            Swal.fire({
              title: 'Carregando...',
              showConfirmButton: false,
              allowOutsideClick: false,
              allowEscapeKey: false,
              allowEnterKey: false,
              onBeforeOpen: () => {
                Swal.showLoading()
              },
            })

            try {
              // Atualiza o usuário
              const dadosDeUsuario = { ...this.optionsLocal }
              await useJwt.update(`cadastro/user/updateUsuario`, this.optionsLocal.id, dadosDeUsuario)


              Swal.close()
              this.$swal({
                title: 'Atenção!',
                text: 'Registro alterado com sucesso!',
                icon: 'success',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              })
            } catch (error) {
              console.error(error)
            }
          }
        })
      },
    },

    setup() {
      const refInputEl = ref(null)
      const refPreviewEl = ref(null)

      const { inputImageRenderer } = useInputImageRenderer(refInputEl, (base64) => {
        refPreviewEl.value.src = base64
      })

      return {
        refInputEl,
        refPreviewEl,
        inputImageRenderer,
      }
    },
  }
</script>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
  @import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
