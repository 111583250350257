import useJwt from '@/auth/jwt/useJwt'

export default async function updateUserData(): Promise<void> {
  // Get userData from localStorage
  const userData = JSON.parse(localStorage.getItem('userData'))
  // If userData is null, return
  if (!userData) return
  // Get user data from backend
  const response = await useJwt.get('auth/GetUserData/' + userData.id)

  localStorage.removeItem('userData')
  localStorage.setItem('userData', JSON.stringify(response.data))
}
