<template>
  <b-tabs>
    <!--Configurações Gerais-->
    <b-tab active>
      <template #title>
        <feather-icon icon="UserIcon" size="18" class="mr-50" />
        <span class="font-weight-bold">Configurações Gerais</span>
      </template>
      <configuracaoContaGeral v-if="options" :general-data="options" />
    </b-tab>

    <!--Mudar a senha-->
    <b-tab>
      <template #title>
        <feather-icon icon="LockIcon" size="18" class="mr-50" />
        <span class="font-weight-bold">Mudar a senha</span>
      </template>
      <configuracaoContaSenha v-if="options" :general-data="options" />
    </b-tab>
  </b-tabs>
</template>

<script>
  import configuracaoContaGeral from './configuracaoContaGeral.vue'
  import configuracaoContaSenha from './configuracaoContaSenha.vue'
  import useJwt from '@/auth/jwt/useJwt'
  import { jwtDecode } from 'jwt-decode'

  export default {
    components: {
      configuracaoContaGeral,
      configuracaoContaSenha,
    },
    data() {
      return {
        options: jwtDecode(JSON.parse(localStorage.getItem('userData')))['userData'],
      }
    },
  }
</script>
